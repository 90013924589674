<template>
  <div id="adviceDetail">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'adviceList' }"
        >用户反馈</el-breadcrumb-item
      >
      <el-breadcrumb-item>详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="basicsInfo">
      <h3>基础信息</h3>
      <ul class="info">
        <li>
          <span class="label">车牌号：</span>
          <editable-input :value="info.plateNum" />
        </li>
        <li>
          <span class="label">类型：</span>
          <editable-input :value="info.adviceName" />
        </li>
        <li>
          <span class="label">时间：</span>
          <editable-input :value="info.submitAt" />
        </li>
        <li>
          <span class="label">停车场：</span>
          <editable-input :value="info.parkingLotName" />
        </li>
        <li>
          <span class="label">联系人：</span>
          <editable-input :value="info.contactPerson" />
        </li>
        <li>
          <span class="label">联系人手机号：</span>
          <editable-input :value="info.contactNumber" />
        </li>
        <li>
          <span class="label">金额：</span>
          <editable-input :value="info.payAmount" />
        </li>
        <li>
          <span class="label">反馈描述：</span>
          <editable-input :value="info.adviceText" />
        </li>
        <li>
          <span class="label">广告图片：</span>
          <div class="custom-module advertisingImg">
            <el-image :src="info.picture" :preview-src-list="info.pictureList">
            </el-image>
          </div>
        </li>
        <li>
          <span class="label">状态：</span>
          <editable-input
            :value="info.solvedFlag === 0 ? '未解决' : '已解决'"
          />
        </li>
        <li>
          <span class="label">解决方案：</span>
          <editable-input :value="info.solution" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    editableInput: () => import("@/components/editable-input.vue"),
    editableSelect: () => import("@/components/editable-select.vue"),
  },
  data() {
    return {
      adviceId: "",
      info: {},
    };
  },
  created() {
    this.info = JSON.parse(this.$route.params.info);
    console.log(this.info);
  },
  methods: {},
};
</script>

<style lang="scss">
#adviceDetail {
  .advertisingImg {
    .el-image {
      height: 50px;
    }
  }
  .tab-table {
    background-color: white;
    padding: 15px;
  }
}
</style>
